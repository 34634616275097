<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/download/store/axg/index.vue
 * @Author: 张兴业
 * @Date: 2020-12-29 14:44:07
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-11-17 14:54:22
-->

<template>
  <div class="container">
    <!-- 
      pc端
     -->
    <div v-if="!system.isPhone">
      <el-image :src="config.bg" fit="cover" class="bg" />
      <el-image :src="config.brand" class="app_brand" />
      <div class="left_top">
        <div class="logo_back">
          <el-image :src="config.logo" class="logo" />
          <!-- <span class="logo_title0">
            <span class="logo_title1">{{ config.app_title1 }}</span>
            <span class="logo_title2">{{ config.app_title2 }}</span>
          </span> -->
          <!-- <span class="logo_title">全球好货通全球</span> -->
        </div>
      </div>
      <div class="qrcode">
        <div class="hint_title">{{ config.hint_content }}</div>
        <div class="qrcode_content">
          <div class="qrcode_l">
            <el-image :src="config.ios_qrcode" class="qrcode_img" />
            <a :href="config.ios_link">
              <el-image :src="config.ios_btn" class="app_btn" />
            </a>
          </div>
          <div class="qrcode_r">
            <el-image :src="config.android_qrcode" class="qrcode_img" />
            <el-image
              v-if="isWxClient && isMobileTerminal"
              :src="config.android_btn"
              class="app_btn"
              @click="showGuid"
            />
            <a v-else :href="config.android_link">
              <el-image :src="config.android_btn" class="app_btn" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 
      wap端
     -->
    <div v-if="system.isPhone">
      <Wap
        :ios="ios"
        :isWxClient="isWxClient"
        :isMobileTerminal="isMobileTerminal"
        :android_cdn="android_cdn"
        @showGuid="showGuid"
      />
    </div>
    <div v-if="show" class="guid_back" @click.stop="closeGuid">
      <div class="guid">
        <img src="@/assets/download/guid.png" alt="" />
      </div>
      <div class="mask_c">
        <div class="hint1">1. 点击右上角</div>
        <div class="hint2">
          {{
            !isIPhoneOrIPad
              ? "2. 点击`在浏览器中打开`"
              : "2. 点击`在Safari中打开`"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isIPhoneOrIPad,
  isWxClient,
  isMobileTerminal,
  isAndroid
} from "@/utils/pattern";
import Wap from "./components/Wap";
import AxgConfig from "./config";

export default {
  components: {
    Wap
  },
  data() {
    return {
      show: false,
      ios: "itms-apps://itunes.apple.com/app/1590792955",
      // android:
      //   "https://a.app.qq.com/o/simple.jsp?pkgname=com.azgo.globalproducts",
      android_cdn: "https://assets-apk.myazstore.com/app/axg-android.apk"
    };
  },
  computed: {
    isIPhoneOrIPad() {
      return isIPhoneOrIPad();
    },
    isWxClient() {
      return isWxClient();
    },
    isMobileTerminal() {
      return isMobileTerminal();
    },
    isAndroid() {
      return isAndroid();
    },
    config() {
      return AxgConfig;
    }
  },
  mounted() {
    if (this.isAndroid && this.isWxClient) {
      this.show = true;
    }
  },
  methods: {
    showGuid() {
      if (!this.isWxClient) {
        return;
      }
      this.show = true;
    },
    closeGuid() {
      this.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  position: relative;

  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .app_brand {
    position: absolute;
    // bottom: 14.35%;
    // right: 17.08%;
    // width: 19%;
    top: 50%;
    right: 16%;
    width: 19%;
    transform: translateY(-50%);
  }

  .left_top {
    position: absolute;
    top: 13.9%;
    left: 10.4%;
    width: 80%;
    text-align: left;
    color: #000000;
    font-family: PingFangSC-Light, PingFang SC;

    .logo_back {
      display: flex;
      flex-direction: row;

      .logo {
        width: 18.85%;
        height: calc(100%);
      }

      .logo_title0 {
        font-size: 28px;
        font-weight: 300;
        margin: auto 24px;

        .logo_title1 {
          color: #bf9264;
          font-weight: 700;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        }

        .logo_title2 {
          font-weight: 700;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        }
      }

      .logo_title {
        // margin-top: 30px;
        font-size: 28px;
        font-weight: 300;
        margin: auto 0;
      }
    }
  }

  .qrcode {
    position: absolute;
    left: 10.4%;
    bottom: 25%;
    width: 28.125%;

    .logo_subtitle {
      font-size: 24px;
      font-weight: 400;
      width: 800px;
      text-align: left;
    }

    .hint_title {
      font-size: 22px;
      text-align: left;
      color: rgba(0, 0, 0, 0.6);
    }

    .qrcode_content {
      margin-top: 66px;
      display: flex;
    }

    .qrcode_l,
    .qrcode_r {
      width: 40.7%;
      text-align: center;
    }

    .qrcode_r {
      margin-left: 20%;
    }

    .qrcode_img {
      width: 100%;
    }

    .app_btn {
      margin-top: 30px;
      width: 100%;
      display: block;
    }
  }

  .guid_back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .guid {
    text-align: right;
    padding: 8px 16px;
  }
  .guid img {
    width: 120px;
    height: 156px;
  }
  .mask_c {
    background: #fff;
    margin: 8px 24px auto 24px;
    border-radius: 5px;
    padding: 12px;
    font-size: 14px;
    color: #000;
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    height: 100vh;

    .bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .app_brand {
      position: absolute;
      bottom: 4%;
      left: 50%;
      transform: translateX(-50%);
      width: 35%;
    }

    .left_top {
      position: absolute;
      top: 8%;
      left: 50%;
      transform: translateX(-50%);
      width: 92%;
      text-align: center;
      color: #000000;
      font-family: PingFangSC-Light, PingFang SC;

      .logo_back {
        display: flex;
        flex-direction: column;
        .logo {
          width: 50%;
          margin: auto;
        }

        .logo_title0 {
          margin: 20px auto;
        }

        .logo_title {
          font-size: 20px;
          font-weight: 300;
        }
      }
    }

    .qrcode {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      .logo_subtitle {
        font-size: 13px;
        font-weight: 400;
        width: 100%;
        text-align: center;
      }

      .qrcode_content {
        margin-top: 30px;
        display: flex;
      }

      .qrcode_l,
      .qrcode_r {
        width: 40%;
        text-align: center;
      }

      .qrcode_r {
        margin-left: 20%;
      }

      .qrcode_img {
        width: 68.2%;
      }

      .app_btn {
        margin-top: 10px;
        width: 100%;
        display: block;
      }
    }
  }
}
</style>
