<template>
  <div class="wap_con">
    <div class="con_con">
      <!-- logo -->
      <!-- <el-image
        :src="require('@/assets/img/wapdownload/az_haohuotong_logo.png')"
        alt=""
        class="wap_logo"
      /> -->
      <div class="logo_back">
        <el-image :src="axgConfig.logo" class="logo" />
        <!-- <span class="logo_title0">
          <span class="logo_title1">{{ axgConfig.app_title1 }}</span>
          <span class="logo_title2">{{ axgConfig.app_title2 }}</span>
        </span> -->
        <!-- <span class="logo_title">全球好货通全球</span> -->
      </div>
      <!-- 二维码 -->
      <div class="qrcode_con">
        <div class="qrcode_l">
          <el-image :src="axgConfig.ios_qrcode" class="qrcode_img" />
          <a :href="ios">
            <el-image :src="axgConfig.ios_btn" class="app_btn" />
          </a>
        </div>
        <div class="qrcode_r">
          <el-image :src="axgConfig.android_qrcode" class="qrcode_img" />
          <el-image
            v-if="isWxClient && isMobileTerminal"
            :src="axgConfig.android_btn"
            class="app_btn"
            @click="showGuid"
          />
          <a v-else :href="android_cdn">
            <el-image :src="axgConfig.android_btn" class="app_btn" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AxgConfig from "../config";
export default {
  props: {
    ios: {
      type: String,
      default: ""
    },
    android_cdn: {
      type: String,
      default: ""
    },
    isWxClient: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    isMobileTerminal: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  computed: {
    axgConfig() {
      return AxgConfig;
    }
  },
  methods: {
    showGuid() {
      this.$emit("showGuid");
    }
  }
};
</script>

<style lang="less" scoped>
.wap_con {
  padding: 70px 24px 0;
  background: #f5f5f5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .logo_back {
    display: flex;
    flex-direction: row;

    .logo {
      width: 64%;
      margin: auto;
    }

    .logo_title0 {
      font-size: 28px;
      font-weight: 300;
      margin: auto auto auto 4px;

      .logo_title1 {
        color: #bf9264;
        font-weight: 700;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      }

      .logo_title2 {
        font-weight: 700;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      }
    }

    .logo_title {
      // margin-top: 30px;
      font-size: 28px;
      font-weight: 300;
      margin: auto 0;
    }
  }
}
.con_con {
  background: #fff;
  border-radius: 12px;
  padding: 83px 0 97px;
}
.wap_logo {
  display: block;
  width: 150px;
  margin: 0 auto;
}
.qrcode_con {
  // position: absolute;
  // top: 83px;
  // bottom: 97px;
  margin-top: 84px;
  display: flex;
  justify-content: center;
  .qrcode_l,
  .qrcode_r {
    text-align: center;
  }

  .qrcode_r {
    margin-left: 25px;
  }

  .qrcode_img {
    width: 123px;
  }

  .app_btn {
    margin-top: 30px;
    width: 123px;
    display: block;
  }
}
</style>
